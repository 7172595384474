import Button from '@mui/material/Button';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import React, { useEffect, useState } from 'react';
import container from '../../services/container';
import { UseStyles } from 'hooks';
import Tooltip from '@mui/material/Tooltip';
import { CircularProgress } from '@mui/material';
import authApi from 'services/api/auth';
import { useConfig } from 'hooks/useConfig';

declare global {
  interface Window {
    Kustomer: any;
  }
}

export function HelpChat(): JSX.Element {
  const classes = UseStyles();
  const appshell = container.get('appshell');
  const authUser = appshell?.user ?? null;
  const staticText = {
    HelpLoading: 'Loading...',
    footerHelpBtn: 'Need help?',
  };
  const { configVar: kustomerApiKey } = useConfig('kustomer_api_key');
  const { configVar: kustomerApiBrand } = useConfig('kustomer_api_brand');
  const [status, setStatus] = useState('start');
  const [initiateSign, setInitiateSign] = useState(false);
  useEffect(() => {
    const kustomerInit = () => {
      window.Kustomer?.start(
        {
          brandId: kustomerApiBrand,
        },
        function () {
          setStatus('done');
        }
      );
    };
    if (status === 'done') {
      openChat();
      window.removeEventListener('kustomerLoaded', kustomerInit);
    }
    window.addEventListener('kustomerLoaded', kustomerInit);
    return () => window.removeEventListener('kustomerLoaded', kustomerInit);
  }, [status]);

  useEffect(() => {
    (async () => {
      if (status === 'done' && authUser && !initiateSign) {
        setInitiateSign(true);
        const { token } = (await authApi.token()) as unknown as { token: string };
        window.Kustomer.login(
          {
            jwtToken: token,
          },
          (loginCallbackResponse: any, error: Error) => {
            if (error) {
              console.error(error);
            }
          }
        );
      }
    })();
  }, [status, authUser]);

  const new_script = (src: string, id: string, dataKustomerApiKey: string) => {
    return new Promise<void>(function (resolve, reject) {
      const script = document.createElement('script');
      script.src = src;
      script.id = id;
      if (dataKustomerApiKey) {
        script.setAttribute('data-kustomer-api-key', dataKustomerApiKey);
      }
      script.addEventListener('load', function () {
        resolve();
      });
      script.addEventListener('error', function (e) {
        reject(e);
      });
      document.body.appendChild(script);
    });
  };

  const do_load = () => {
    if (status !== 'done') {
      setStatus('loading');
      const kustomer_script = new_script(
        'https://cdn.kustomerapp.com/chat-web/widget.js',
        'kustomer-snippet',
        kustomerApiKey!
      );
      kustomer_script.catch((error) => {
        setStatus('error');
      });
    } else {
      openChat();
    }
  };

  const openChat = () => {
    if (status === 'done') {
      window.Kustomer?.open();
    }
  };

  return (
    <Tooltip
      title={status === 'loading' ? staticText.HelpLoading : staticText.footerHelpBtn}
      aria-label={status === 'loading' ? staticText.HelpLoading : staticText.footerHelpBtn}
    >
      <Button
        className={`${classes.topHelperBtn}
          ${classes.help}
          ${classes.noPrint}`}
        aria-label='back'
        onClick={do_load}
        data-id='help-btn'
      >
        {status === 'loading' ? <CircularProgress className='icon' /> : <HelpOutlineIcon className='icon' />}
        {status === 'loading' && staticText.HelpLoading}
        {status !== 'loading' && staticText.footerHelpBtn}
      </Button>
    </Tooltip>
  );
}
