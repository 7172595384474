import { AxiosPromise } from 'axios';
import { SuccessResponse } from 'services/api/client';
import useSWR, { SWRResponse } from 'swr';
import * as Sentry from '@sentry/nextjs';
import CheckoutApi from 'services/api/checkout';
import { useStoreon } from 'storeon/react';
import { CheckoutApi as CheckoutApiClass } from 'services/api/client';
import { generateUniqueId } from 'utils';

type ReturnType<T> = {
  data: T | undefined;
  mutate: any;
  error: boolean;
  loading: boolean;
};

const useSWRoptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

export const useCheckoutApi = <T, TData = any>(method: keyof CheckoutApiClass, args: TData[] = []): ReturnType<T> => {
  const { dispatch } = useStoreon();
  const _method = CheckoutApi[method] as (...args: TData[]) => AxiosPromise<SuccessResponse>;
  const { data, error, mutate, isValidating } = useSWR(
    method + generateUniqueId(args),
    () => _method.call(CheckoutApi, ...args),
    useSWRoptions
  );
  if (error) {
    const type = error.data?.error?.type;
    const errorTypes = ['internal.user.corrupted', 'access.user.subscription'];
    if (type && errorTypes.includes(type)) {
      dispatch('context/set', ['brokenUser', true]);
    }
    Sentry.captureException(error);
  }
  return {
    data: data as unknown as T,
    mutate,
    error: false,
    loading: isValidating,
  };
};
