import React, { useState, useEffect } from 'react';
import { FormHelperText } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import MuiTextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import { stateForces, states } from 'utils/helpers/helpers';
import { ClassNameMap } from '@mui/styles/withStyles';
import { debounce } from 'debounce';
import CircularProgress from '@mui/material/CircularProgress';
import memberApi from 'services/api/member';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import { useConfig } from 'hooks/useConfig';
import { usePlacesWidget } from 'react-google-autocomplete';
import { TextField, RadioGroup, Select } from '@components/Form';
import { useCheckoutApi } from 'hooks/useCheckoutApi';
import { EnrollInfo200ResponseAllOfData } from 'services/api/client';

type Props = {
  classes: ClassNameMap;
  isPost: boolean;
  isBilling?: boolean;
  isRanks?: boolean;
};

export function AddressBlock({ isBilling = false, isRanks = false, classes, isPost }: Props): JSX.Element {
  const { data: { ranks = {} } = {} } = useCheckoutApi<EnrollInfo200ResponseAllOfData>('enrollInfo');
  const { trigger, errors, getValues, setValue } = useFormContext();
  const disableUsps = true;
  const nameMod = isBilling ? 'billing_' : '';
  const fieldPrefix = isBilling ? 'billing_' : '';
  const [open, setOpen] = useState(false);
  const [searchAddress, setSearchAddress] = useState({});
  const [uspsError, setUspsError] = useState(false);
  const debouncedTriggerPostalCode = debounce(() => trigger(`${nameMod}postal_code`), 500);
  const {
    state: { selectedPlan },
  } = UseCheckoutDetails();
  const { configVar: mapsApiKey } = useConfig('google_maps_js_key');
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = async () => {
    if (disableUsps) return;
    if (!getValues) return;
    const { city, line1, line2, postal_code, state, type } = getValues();
    if ('home' !== type || !city || !line1 || !postal_code || !state) {
      return;
    }
    const address = { type, line1, line2: line2 || null, city, postal_code, state };
    if (JSON.stringify(address) === JSON.stringify(searchAddress)) {
      return;
    }
    setOpen(true);
    try {
      await memberApi.validate({ address });
      setUspsError(false);
    } catch (e) {
      setUspsError(true);
    }
    setSearchAddress(address);
    setOpen(false);
  };
  const { ref } = usePlacesWidget({
    apiKey: mapsApiKey!,
    onPlaceSelected: (place) => {
      if (!setValue) return;
      const address: { [key: string]: string } = {};
      const addressNameFormat: {
        [key: string]: { type: 'short_name' | 'long_name'; field?: string };
      } = {
        country: { type: 'short_name' },
        street_number: { type: 'short_name' },
        route: { field: 'line1', type: 'long_name' },
        locality: { field: 'city', type: 'long_name' },
        administrative_area_level_1: { field: 'state', type: 'short_name' },
        postal_code: { type: 'short_name' },
        postal_code_suffix: { type: 'short_name' },
      };
      for (const component of place.address_components) {
        const type: keyof typeof addressNameFormat = component.types[0];
        const map = addressNameFormat[type];
        if (map) {
          address[map.field || type] = component[map.type];
        }
      }
      const line1 = [address['street_number'], address['line1']].filter((v) => v).join(' ') || '';
      (ref.current! as { value: string }).value = line1;
      setValue(`${nameMod}line1`, line1);
      setValue(`${nameMod}city`, address['city'] || '');
      setValue(`${nameMod}state`, address['state'] || '');
      const postal_code = [address['postal_code'], address['postal_code_suffix']].filter((v) => v).join('-');
      setValue(`${nameMod}postal_code`, postal_code || '');
    },
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    },
  });
  const values = getValues ? getValues() : {};
  const line1 = values[`${nameMod}line1`];
  useEffect(() => {
    if (line1 && ref.current) {
      (ref.current! as { value: string }).value = line1;
    }
  }, [line1]);
  const stateOptions = isPost ? stateForces : states;
  return (
    <>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color='inherit' />
        <b style={{ paddingLeft: 10 }}>Verifying address details...</b>
      </Backdrop>
      <RadioGroup
        name={`${fieldPrefix}type`}
        options={[
          { id: 'post', name: 'APO/FPO/DPO' },
          { id: 'home', name: 'Home/Office Address' },
        ]}
      />
      {uspsError && (
        <Typography component='h3' variant='h3' style={{ fontSize: 18 }}>
          We were unable to find your address in the USPS database. Please check your address.
          {!selectedPlan?.trial && 'year' === selectedPlan?.period?.unit && (
            <> Skipping Addresses Validation may impact delivery.</>
          )}
        </Typography>
      )}
      {isPost && isRanks && ranks && (
        <Select
          label='Rank'
          name='rank'
          options={Object.keys(ranks).map((rank_id) => ({ id: rank_id, name: ranks[rank_id] }))}
        />
      )}
      <div className={classes.fieldWrapper}>
        <label className={classes.fieldLabel}>Address line 1</label>
        <MuiTextField
          fullWidth
          name={`${fieldPrefix}address`}
          onBlur={handleToggle}
          onChange={(e) => {
            if (setValue) {
              setValue(`${fieldPrefix}line1`, e.target.value);
            }
          }}
          variant='outlined'
          placeholder='address'
          error={!!errors[`${fieldPrefix}line1`]}
          inputRef={ref}
          style={{ display: isPost || !mapsApiKey ? 'none' : 'block' }}
        />
        <TextField
          fullWidth
          defaultValue=''
          name={`${fieldPrefix}line1`}
          onBlur={handleToggle}
          placeholder='address'
          error={!!errors[`${fieldPrefix}line1`]}
          style={{ display: isPost || !mapsApiKey ? 'block' : 'none' }}
        />
      </div>
      <TextField
        label='Apt, floor, suite, etc.'
        fullWidth
        name={`${fieldPrefix}line2`}
        defaultValue=''
        onBlur={handleToggle}
        placeholder='floor 1'
        error={!!errors[`${fieldPrefix}line2`]}
      />
      {isPost ? (
        <Select
          label='City'
          defaultValue=''
          fullWidth
          name={`${fieldPrefix}city`}
          error={!!errors[`${fieldPrefix}city`]}
          options={[
            { id: 'APO', name: 'APO' },
            { id: 'FPO', name: 'FPO' },
            { id: 'DPO', name: 'DPO' },
          ]}
        />
      ) : (
        <TextField
          label='City'
          defaultValue=''
          fullWidth
          name={`${fieldPrefix}city`}
          onBlur={handleToggle}
          placeholder='City'
          error={!!errors[`${fieldPrefix}city`]}
        />
      )}
      <div className={classes.fieldWrapper}>
        <TextField
          label='Zip Code'
          defaultValue=''
          fullWidth
          name={`${fieldPrefix}postal_code`}
          onBlur={handleToggle}
          placeholder='08901'
          error={!!errors[`${fieldPrefix}postal_code`]}
          onChange={() => {
            debouncedTriggerPostalCode();
          }}
        />
        {errors[`${nameMod}postal_code`] && (
          <FormHelperText error>Value has invalid type. ZIP or ZIP+4 code expected.</FormHelperText>
        )}
      </div>
      <Select
        label={isPost ? 'Region' : 'State'}
        fullWidth
        name={`${fieldPrefix}state`}
        error={!!errors[`${fieldPrefix}state`]}
        options={Object.keys(stateOptions).map((id) => ({ id, name: stateOptions[id as keyof typeof stateOptions] }))}
      />
    </>
  );
}
