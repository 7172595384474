import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectProps } from '@mui/material/Select';
import { withStyles } from '@mui/styles';
import Menu, { MenuProps } from '@mui/material/Menu';

type CategoryItem = {
  id: string;
  name: string;
};

type Props = {
  defaultValue?: string;
  description?: React.ReactNode | string;
  label?: React.ReactNode | string;
  name: string;
  hideDefaultOption?: boolean;
  onChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  options: CategoryItem[];
  required?: boolean;
  value?: React.ReactNode | string;
  theme?: 'default' | 'dark';
} & SelectProps;

const DarkStyledMenuProps = {
  PaperProps: {
    style: {
      width: 250,
      boxShadow: 'none',
      borderRadius: '0',
    },
  },
  MenuListProps: {
    style: {
      padding: 0,
    },
  },
};

const StyledMuiSelect = withStyles({})(MuiSelect);

const DarkStyledMuiSelect = withStyles((theme) => ({
  outlined: {
    minWidth: 250,
    border: `1px solid ${theme.custom.mediumGray}`,
    lineHeight: '20px',
    borderRadius: '2px',
    '&:hover': {
      borderColor: theme.custom.slate,
    },
    '&:focus': {
      background: 'transparent',
    },
  },
}))(MuiSelect);

export const DarkStyledMenuItem = withStyles((theme) => ({
  root: {
    lineHeight: '42px',
    backgroundColor: 'white',
    border: `1px solid ${theme.custom.slate}`,
    borderBottom: '0 none',
    '&:focus': {
      backgroundColor: 'white',
    },
    '&.Mui-selected:hover,&:hover,&.Mui-selected': {
      color: theme.custom.white,
      backgroundColor: theme.custom.slate,
    },
    '&:last-child': {
      borderBottom: `1px solid ${theme.custom.slate}`,
    },
  },
}))(MenuItem);

function DarkMenu(props: MenuProps): React.ReactElement {
  return (
    <Menu
      MenuListProps={{
        style: {
          padding: 0,
        },
      }}
      PaperProps={{
        style: {
          width: 250,
          boxShadow: 'none',
          borderRadius: '0',
        },
      }}
      {...props}
    >
      {props.children}
    </Menu>
  );
}

export default DarkMenu;
