import React from 'react';
import clsx from 'clsx';
import { useFormContext, get } from 'react-hook-form';
import { FieldError } from 'react-hook-form/dist/types';
import { UseStyles } from 'hooks';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';
import Tooltip from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import LinearProgress from '@mui/material/LinearProgress';
// import useObservable from 'inc/hooks/useObservable';

type Props = {
  children: React.ReactNode;
  description?: string | React.ReactNode;
  errorAltText?: string | React.ReactNode;
  helperText?: string | React.ReactNode;
  helperTextTooltip?: React.ReactNode | string;
  helperTextBottom?: React.ReactNode | string;
  hidden?: boolean;
  label?: React.ReactNode | string;
  name: string;
  required?: boolean;
  noMargin?: boolean;
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

function FieldWrapper({
  description,
  errorAltText,
  helperText,
  helperTextTooltip,
  helperTextBottom,
  hidden,
  label,
  name,
  required,
  noMargin,
  children,
}: Props): JSX.Element {
  const classes = UseStyles([
    'accountFieldWrapper',
    'accountFieldWrapperNoMargin',
    'accountFieldLabel',
    'helperTooltip',
    'helperTooltipIcon',
    'helperText',
    'helperTextIcon',
    'description',
    'accountFieldErrorMessage',
    'accountFieldHelp',
  ]);
  const { errors } = useFormContext();
  const loading = false;
  const error = get(errors, name);
  // const loading = useObservable('data.loading');
  return (
    <div
      className={clsx(classes.accountFieldWrapper, {
        // [classes.error]: !!error,
        error: !!error,
        [classes.accountFieldWrapperNoMargin]: !!noMargin,
      })}
      {...(hidden ? { style: { display: 'none' } } : {})}
      // {...props}
    >
      <>
        {label && (
          <label className={classes.accountFieldLabel}>
            {label}
            {required ? <span> *</span> : null}
            {!!helperTextTooltip && (
              <Tooltip classes={{ tooltip: classes.helperTooltip }} placement='right' title={helperTextTooltip}>
                <HelpIcon className={classes.helperTooltipIcon} color='primary' />
              </Tooltip>
            )}
          </label>
        )}
        {!!helperText && (
          <div className={classes.helperText}>
            <HelpOutlinedIcon className={classes.helperTextIcon} />
            {helperText}
          </div>
        )}
        {!!description && (
          // <div style={{ fontSize: 12, fontStyle: 'italic', lineHeight: '15px', marginBottom: 3, marginTop: 3 }}>
          <div className={classes.description}>{description}</div>
        )}
      </>
      {children}
      {loading && <LinearProgress />}
      {!!error && (
        <div className={classes.accountFieldErrorMessage}>{errorAltText || (error as FieldError).message}</div>
      )}
      {!!helperTextBottom && <div className={classes.accountFieldHelp}>{helperTextBottom}</div>}
    </div>
  );
}

export default FieldWrapper;
