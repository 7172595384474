// @ts-nocheck
import React from 'react';
import { withStyles } from '@mui/styles';
import FieldWrapper from './FieldWrapper';
import MuiTextField from '@mui/material/TextField';
import MuiAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { Controller, useFormContext } from 'react-hook-form';

const CustomMuiAutocomplete = withStyles({
  inputRoot: {
    backgroundColor: '#fff',
    padding: '0 30px 0 10px!important',
  },
})(MuiAutocomplete);

type Props = {
  defaultValue?: string;
  description?: React.ReactNode | string;
  label?: string;
  name: string;
  onChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  options: CategoryItem[];
  required?: boolean;
} & AutocompleteProps;

function Autocomplete({
  defaultValue = '',
  description,
  label,
  name,
  onChange,
  options,
  required,
  ...props
}: Props): JSX.Element {
  const { control } = useFormContext();
  const getOpObj = (option) => {
    if (!option.id) option = options.find((op) => op.id === option);
    return option;
  };
  return (
    <FieldWrapper helperText={description} label={label} name={name} required={required}>
      <Controller
        defaultValue={null}
        name={name}
        control={control}
        render={({ onChange, value }) => (
          <CustomMuiAutocomplete
            autoComplete={true}
            autoHighlight={true}
            freeSolo
            getOptionLabel={(option: CategoryItem) => getOpObj(option)?.name || ''}
            forcePopupIcon={false}
            onChange={(e, data) => onChange(data?.id)}
            options={options}
            renderInput={(params: Data) => {
              return (
                <MuiTextField
                  {...{
                    ...params,
                    inputProps: { ...(params.inputProps || {}), role: 'textbox' },
                  }}
                  // error={error}
                  variant='outlined'
                />
              );
            }}
            value={value}
            // {...props}
          />
        )}
      />
    </FieldWrapper>
  );
}

export default Autocomplete;
