import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectProps } from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { withStyles } from '@mui/styles';
import FieldWrapper from './FieldWrapper';
import { useForm, Controller, useFormContext } from 'react-hook-form';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiRadio from '@mui/material/Radio';
import MuiRadioGroup from '@mui/material/RadioGroup';
// import useField from 'inc/hooks/useField';

type CategoryItem = {
  id: string;
  name: React.ReactNode | string;
};

type Props = {
  defaultValue?: string;
  description?: React.ReactNode | string;
  disabled?: boolean;
  label?: string;
  inline?: boolean;
  name: string;
  onChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  options: CategoryItem[];
  required?: boolean;
  value?: React.ReactNode | string;
};

function RadioGroup({
  defaultValue,
  description,
  disabled = false,
  inline = true,
  label,
  name,
  onChange,
  options,
  required,
  value,
}: Props): JSX.Element {
  const { control } = useFormContext();
  return (
    <FieldWrapper helperText={description} label={label} name={name} required={required}>
      <Controller
        defaultValue={defaultValue ? defaultValue : null}
        name={name}
        control={control}
        render={({ onChange, value }) => (
          <MuiRadioGroup onChange={onChange} row={inline} aria-label={name} value={value}>
            {options.map((option: { id: string; name: React.ReactNode }) => (
              <FormControlLabel
                disabled={disabled}
                key={option.id}
                value={option.id}
                control={<MuiRadio color='primary' />}
                label={option.name}
              />
            ))}
          </MuiRadioGroup>
        )}
      />
    </FieldWrapper>
  );
}

export default RadioGroup;
