import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect, { SelectProps } from '@mui/material/Select';
import { withStyles } from '@mui/styles';
import FieldWrapper from './FieldWrapper';
import { Controller, useFormContext } from 'react-hook-form';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { DarkStyledMenuItem } from './DarkMenu';

type CategoryItem = {
  id: string;
  name: string;
};

type Props = {
  defaultValue?: string;
  description?: React.ReactNode | string;
  label?: React.ReactNode | string;
  name: string;
  hideDefaultOption?: boolean;
  onChange?: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
  options: CategoryItem[];
  required?: boolean;
  value?: React.ReactNode | string;
  theme?: 'default' | 'dark';
} & SelectProps;

export const DarkStyledMenuProps = {
  PaperProps: {
    style: {
      width: 250,
      boxShadow: 'none',
      borderRadius: '0',
    },
  },
  MenuListProps: {
    style: {
      padding: 0,
    },
  },
};

const StyledMuiSelect = withStyles({})(MuiSelect);

export const DarkStyledMuiSelect = withStyles((theme) => ({
  outlined: {
    minWidth: '250px!important',
    border: `1px solid ${theme.custom.mediumGray}`,
    lineHeight: '20px!important',
    borderRadius: '2px!important',
    '&:hover': {
      borderColor: theme.custom.slate,
    },
    '&:focus': {
      background: 'transparent',
    },
  },
}))(MuiSelect);

function Select({
  defaultValue = '',
  description,
  label,
  name,
  onChange,
  options,
  required,
  value,
  hideDefaultOption = false,
  theme = 'default',
  ...props
}: Props): JSX.Element {
  if (process.env.REACT_APP_TESTING) {
    props['inputProps'] = {
      'data-testid': `${name}-input`,
    };
    props.native = true;
  }
  const { control } = useFormContext();
  let selectValue = value || defaultValue;
  if (selectValue && !options.find((option) => option.id === selectValue)) {
    selectValue = defaultValue ? defaultValue : '';
  }
  return (
    <FieldWrapper helperText={description} label={label} name={name} required={required}>
      <Controller
        defaultValue={defaultValue}
        name={name}
        control={control}
        render={({ onChange, value }) => {
          if (theme === 'dark') {
            return (
              <DarkStyledMuiSelect
                name={name}
                onChange={onChange}
                IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
                variant='outlined'
                fullWidth
                value={value}
                MenuProps={DarkStyledMenuProps}
                {...props}
              >
                {!hideDefaultOption && <DarkStyledMenuItem value={undefined}>--- Select ---</DarkStyledMenuItem>}
                {options.map((option: { id: string; name: string }) => (
                  <DarkStyledMenuItem key={option.id} value={option.id}>
                    {option.name}
                  </DarkStyledMenuItem>
                ))}
              </DarkStyledMuiSelect>
            );
          }

          return (
            <StyledMuiSelect
              name={name}
              onChange={onChange}
              IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
              variant='outlined'
              fullWidth
              value={value}
              {...props}
            >
              {!hideDefaultOption && <MenuItem value={undefined}>--- Select ---</MenuItem>}
              {options.map((option: { id: string; name: string }) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </StyledMuiSelect>
          );
        }}
      />
    </FieldWrapper>
  );
}

export default Select;
