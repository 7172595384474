import React, { useState, useEffect } from 'react';
import { FormControl, FormHelperText } from '@mui/material';
import { Controller, UseFormMethods } from 'react-hook-form';
import MuiTextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import { CityField } from '../CityField';
import { StateRegionField } from '../StateRegionField';
import { stateForces, states, validationPattern } from 'utils/helpers/helpers';
import { ClassNameMap } from '@mui/styles/withStyles';
import { AddressInterface, UserEnrollInterface } from 'api/userEnrollInfo';
import MuiRadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiRadio from '@mui/material/Radio';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { DefaultValuesType } from '../StepsContent/Step3/CompleteInfo';
import { DefaultBillingAddressType } from '../StepsContent/Step3/helpers';
import { debounce } from 'debounce';
import CircularProgress from '@mui/material/CircularProgress';
import memberApi from 'services/api/member';
import { UseCheckoutDetails } from 'context/checkoutDetails.context';
import { useConfig } from 'hooks/useConfig';
import { usePlacesWidget } from 'react-google-autocomplete';

type Props = Partial<UseFormMethods> &
  Partial<UserEnrollInterface> & {
    classes: ClassNameMap;
    defaultValues: Partial<DefaultValuesType>;
    isPost: boolean;
    isBilling?: boolean;
    isRanks?: boolean;
    errors: FieldErrors;
    register: () => void;
    trigger: () => void;
  };

type AddressBlockType = AddressInterface & DefaultBillingAddressType;

export function AddressBlock({
  isBilling = false,
  isRanks = false,
  classes,
  setValue,
  getValues,
  defaultValues,
  isPost,
  register,
  errors,
  control,
  trigger,
  ranks,
}: Props): JSX.Element {
  const disableUsps = true;
  const nameMod = isBilling ? 'billing_' : '';
  const [open, setOpen] = useState(false);
  const [searchAddress, setSearchAddress] = useState({});
  const [uspsError, setUspsError] = useState(false);
  const debouncedTriggerPostalCode = debounce(() => trigger(`${nameMod}postal_code`), 500);
  const {
    state: { selectedPlan },
  } = UseCheckoutDetails();
  const { configVar: mapsApiKey } = useConfig('google_maps_js_key');
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = async () => {
    if (disableUsps) return;
    if (!getValues) return;
    const { city, line1, line2, postal_code, state, type } = getValues();
    if ('home' !== type || !city || !line1 || !postal_code || !state) {
      return;
    }
    const address = { type, line1, line2: line2 || null, city, postal_code, state };
    if (JSON.stringify(address) === JSON.stringify(searchAddress)) {
      return;
    }
    setOpen(true);
    try {
      await memberApi.validate({ address });
      setUspsError(false);
    } catch (e) {
      setUspsError(true);
    }
    setSearchAddress(address);
    setOpen(false);
  };
  const { ref } = usePlacesWidget({
    apiKey: mapsApiKey!,
    onPlaceSelected: (place) => {
      if (!setValue) return;
      const address: { [key: string]: string } = {};
      const addressNameFormat: {
        [key: string]: { type: 'short_name' | 'long_name'; field?: string };
      } = {
        country: { type: 'short_name' },
        street_number: { type: 'short_name' },
        route: { field: 'line1', type: 'long_name' },
        locality: { field: 'city', type: 'long_name' },
        administrative_area_level_1: { field: 'state', type: 'short_name' },
        postal_code: { type: 'short_name' },
        postal_code_suffix: { type: 'short_name' },
      };
      for (const component of place.address_components) {
        const type: keyof typeof addressNameFormat = component.types[0];
        const map = addressNameFormat[type];
        if (map) {
          address[map.field || type] = component[map.type];
        }
      }
      const line1 = [address['street_number'], address['line1']].filter((v) => v).join(' ') || '';
      (ref.current! as { value: string }).value = line1;
      setValue(`${nameMod}line1`, line1);
      setValue(`${nameMod}city`, address['city'] || '');
      setValue(`${nameMod}state`, address['state'] || '');
      const postal_code = [address['postal_code'], address['postal_code_suffix']].filter((v) => v).join('-');
      setValue(`${nameMod}postal_code`, postal_code || '');
    },
    options: {
      types: ['address'],
      componentRestrictions: { country: 'us' },
    },
  });
  const values = getValues ? getValues() : {};
  const line1 = values[`${nameMod}line1`];
  useEffect(() => {
    if (line1 && ref.current) {
      /* eslint-disable react-hooks/exhaustive-deps */
      (ref.current! as { value: string }).value = line1;
    }
  }, [line1]);
  return (
    <>
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <CircularProgress color='inherit' />
        <b style={{ paddingLeft: 10 }}>Verifying address details...</b>
      </Backdrop>
      <MuiRadioGroup
        className={classes.completeInformationRadios}
        row
        aria-label={`${nameMod}type`}
        name={`${nameMod}type`}
        defaultValue={defaultValues[`${nameMod}type` as keyof AddressBlockType] as string}
      >
        <FormControlLabel
          value='post'
          inputRef={register({ required: true })}
          control={<MuiRadio color='primary' />}
          label='APO/FPO/DPO'
        />
        <FormControlLabel
          value='home'
          inputRef={register({ required: true })}
          control={<MuiRadio color='primary' />}
          label='Home/Office Address'
        />
      </MuiRadioGroup>
      {uspsError && (
        <Typography component='h3' variant='h3' style={{ fontSize: 18 }}>
          We were unable to find your address in the USPS database. Please check your address.
          {!selectedPlan?.trial && 'year' === selectedPlan?.period?.unit && (
            <> Skipping Addresses Validation may impact delivery.</>
          )}
        </Typography>
      )}
      {isPost && isRanks && (
        <FormControl error={!!errors.rank} className={classes.fieldWrapper} fullWidth>
          <label className={classes.fieldLabel}>Rank</label>
          <Controller
            name='rank'
            control={control}
            rules={{ required: true }}
            defaultValue={defaultValues.rank}
            as={
              <Select
                displayEmpty
                variant='outlined'
                fullWidth
                IconComponent={(props) => <KeyboardArrowDownIcon {...props} />}
              >
                <MenuItem value='' disabled>
                  --- Select ---
                </MenuItem>
                {ranks &&
                  Object.keys(ranks).map((id) => (
                    <MenuItem key={id} value={id}>
                      {ranks[id]}
                    </MenuItem>
                  ))}
              </Select>
            }
          />
        </FormControl>
      )}
      <div className={classes.fieldWrapper}>
        <label className={classes.fieldLabel}>Address line 1</label>
        <MuiTextField
          fullWidth
          name={`${nameMod}address`}
          onBlur={handleToggle}
          onChange={(e) => {
            if (setValue) {
              setValue(`${nameMod}line1`, e.target.value);
            }
          }}
          variant='outlined'
          placeholder='address'
          error={!!errors[`${nameMod}line1`]}
          inputRef={ref}
          style={{ display: isPost || !mapsApiKey ? 'none' : 'block' }}
        />
        <MuiTextField
          fullWidth
          name={`${nameMod}line1`}
          onBlur={handleToggle}
          variant='outlined'
          placeholder='address'
          error={!!errors[`${nameMod}line1`]}
          inputRef={register({ required: true })}
          style={{ display: isPost || !mapsApiKey ? 'block' : 'none' }}
        />
      </div>
      <div className={classes.fieldWrapper}>
        <label className={classes.fieldLabel}>Apt, floor, suite, etc.</label>
        <MuiTextField
          fullWidth
          name={`${nameMod}line2`}
          onBlur={handleToggle}
          variant='outlined'
          placeholder='floor 1'
          error={!!errors[`${nameMod}line2`]}
          inputRef={register()}
        />
      </div>

      <FormControl error={!!errors.city} className={classes.fieldWrapper} fullWidth>
        <label className={classes.fieldLabel}>{isPost ? 'APO/FPO/DPO' : 'City'}</label>
        <CityField
          name={`${nameMod}city`}
          onBlur={handleToggle}
          control={control}
          register={register}
          defaultValue={defaultValues[`${nameMod}city` as keyof AddressBlockType] as string}
          errors={errors}
          isPost={isPost}
        />
      </FormControl>

      <div className={classes.fieldWrapper}>
        <label className={classes.fieldLabel}>Zip Code</label>
        <Controller
          name={`${nameMod}postal_code`}
          control={control}
          rules={{
            required: true,
            pattern: validationPattern.postal_code,
          }}
          render={({ onChange, value }) => (
            <MuiTextField
              onChange={(e) => {
                onChange(e.target.value);
                debouncedTriggerPostalCode();
              }}
              onBlur={handleToggle}
              value={value}
              fullWidth
              variant='outlined'
              placeholder='08901'
              error={!!errors[`${nameMod}postal_code`]}
            />
          )}
        />
        {errors[`${nameMod}postal_code`] && (
          <FormHelperText error>Value has invalid type. ZIP or ZIP+4 code expected.</FormHelperText>
        )}
      </div>
      <FormControl error={!!errors[`${nameMod}state`]} className={classes.fieldWrapper} fullWidth>
        <label className={classes.fieldLabel}>{isPost ? 'Region' : 'State'}</label>
        <StateRegionField
          name={`${nameMod}state`}
          onChange={handleToggle}
          control={control}
          selectItems={isPost ? stateForces : states}
        />
      </FormControl>
    </>
  );
}
